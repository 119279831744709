@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

.story-container {
  font-family: 'Share Tech Mono', monospace;
  background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
  color: #00ff00;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.explorer-bar {
  font-family: Arial, sans-serif;
  background-color: #e0e0e0;
  color: #000;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #00ff00;
  margin-bottom: 15px;
  font-weight: normal;
}

.content-wrapper {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 15px;
}

.story-content {
  padding-right: 10px;
}

.story-content h1 {
  font-size: 24px;
  color: #00ff00;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.7);
}

.story-content p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #00ff00;
}

.story-content img {
  width: 500px;
  height: auto;
  margin: 20px auto;
  display: block;
  border: 2px solid #00ff00;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.story-content img.reduced-size {
  width: 500px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.button {
  background-color: #000;
  color: #00ff00;
  border: 2px solid #00ff00;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.navigation {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.nav-button {
  background-color: #000;
  color: #00ff00;
  border: 2px solid #00ff00;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-button:hover:not(:disabled) {
  background-color: rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

a {
  color: #00ff00;
  text-decoration: none;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

a:hover {
  text-decoration: underline;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.7);
}

.link-emoji {
  margin-right: 5px;
  font-size: 18px;
}

.content-wrapper::-webkit-scrollbar {
  width: 8px;
}

.content-wrapper::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.content-wrapper::-webkit-scrollbar-thumb {
  background: #00ff00;
  border-radius: 4px;
}

.content-wrapper::-webkit-scrollbar-thumb:hover {
  background: #00cc00;
}

@keyframes slideIn {
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

.slide-animation {
  animation: slideIn 0.5s ease-out;
}