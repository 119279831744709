@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.whack-a-frog {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  cursor: url('./hammer.png'), auto;
  padding: 20px;
  box-sizing: border-box;
}

.whack-a-frog * {
  font-family: 'VT323', monospace;
  box-sizing: border-box;
}

.game-container {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background-color: #000;
  border: 2px solid #00FF00;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-title {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.game-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  color: #00FF00;
  font-size: 18px;
  margin-bottom: 10px;
}

.score, .timer {
  background-color: #000;
  padding: 5px 10px;
  border: 1px solid #00FF00;
  margin: 0 10px;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #001100;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress {
  height: 100%;
  background-color: #00FF00;
  transition: width 1s linear;
}

.game-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 500px;
}

.hole {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-color: #001100;
  border-radius: 50%;
  box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.5);
  transition: transform 0.1s;
}

.hole:active {
  transform: scale(0.95);
}

.target {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  object-fit: contain;
  transition: transform 0.3s;
}

.target.whacked {
  transform: translateY(100%);
}

.whack-a-frog .start-button,
.whack-a-frog .restart-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #000;
  color: #00FF00;
  border: 1px solid #00FF00;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s, transform 0.1s;
}

.whack-a-frog .start-button:hover,
.whack-a-frog .restart-button:hover {
  background-color: #001100;
}

.whack-a-frog .start-button:active,
.whack-a-frog .restart-button:active {
  transform: scale(0.95);
}

.game-over {
  text-align: center;
  color: #00FF00;
  padding: 20px;
}

.game-over h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.game-over p {
  font-size: 18px;
  margin-bottom: 20px;
}

@keyframes pop-up {
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.target {
  animation: pop-up 0.3s;
}