.paint-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 700px;
  min-height: 500px;
  background-color: #000;
  color: #00ff00;
  font-family: 'Courier New', monospace;
}

.toolbar {
  display: flex;
  align-items: center;
  background-color: #000;
  padding: 10px;
  border-bottom: 1px solid #00ff00;
}

.toolbar button {
  background: none;
  border: 1px solid #00ff00;
  color: #00ff00;
  cursor: pointer;
  margin-right: 10px;
  padding: 5px;
  font-family: 'Courier New', monospace;
}

.toolbar button.active {
  background-color: #003300;
}

.toolbar button img {
  width: 24px;
  height: 24px;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.size-options {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.size-button {
  border: 1px solid #00ff00 !important;
  border-radius: 0 !important;
  margin: 0 2px !important;
  padding: 0 !important;
  background-color: #00ff00 !important;
}

.size-button.active {
  border: 2px solid #00ff00 !important;
  background-color: #003300 !important;
}

.color-palette {
  display: flex;
  flex-wrap: wrap;
  max-width: 220px;
}

.color-palette button {
  width: 20px;
  height: 20px;
  border: 1px solid #00ff00;
  margin: 2px;
}

.color-palette button.active {
  border: 2px solid #00ff00;
}

.save-button {
  margin-left: auto;
  background-color: #003300 !important;
  color: #00ff00 !important;
  padding: 8px 16px !important;
  border-radius: 0;
  font-weight: bold;
}

canvas {
  flex-grow: 1;
  background-color: #000;
  cursor: crosshair;
  border: 1px solid #00ff00;
}