@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;700&display=swap');

.hopspad-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #000;
  font-family: 'Fira Code', monospace;
  position: relative;
  z-index: 1;
  border: 1px solid #0f0;
  box-shadow: 0 0 10px rgba(0,255,0,0.3);
}

.hopspad-header {
  background-color: #111;
  border-bottom: 1px solid #0f0;
  padding: 5px;
  color: #0f0;
}

.hopspad-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #111;
  border-bottom: 1px solid #0f0;
}

.style-buttons {
  display: flex;
  gap: 10px;
}

.style-button {
  background-color: #0f0;
  color: #000;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  border-radius: 4px;
}

.style-button:hover {
  background-color: #00ff00;
  transform: scale(1.05);
}

.font-size-label {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #0f0;
}

.font-size-label input {
  width: 50px;
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #0f0;
  border-radius: 4px;
  background-color: #000;
  color: #0f0;
}

.right-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-type-select {
  padding: 5px;
  border: 1px solid #0f0;
  border-radius: 4px;
  background-color: #000;
  color: #0f0;
}

.save-button {
  background-color: #0f0;
  color: #000;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.save-button:hover {
  background-color: #00ff00;
  transform: scale(1.05);
}

.hopspad-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  font-family: 'Fira Code', monospace;
  line-height: 1.5;
  background-color: #000;
  box-shadow: inset 0 0 10px rgba(0,255,0,0.1);
  color: #0f0;
  cursor: text;
  z-index: 2;
  position: relative;
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}

.hopspad-content:focus {
  outline: none;
  box-shadow: inset 0 0 15px rgba(0,255,0,0.2);
}

.placeholder {
  color: #0f07;
}

::selection {
  background-color: rgba(0, 255, 0, 0.3);
  color: #fff;
}