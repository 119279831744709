@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;700&display=swap');

.hops-cmd-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #000;
  color: #0f0;
  font-family: 'Fira Code', monospace;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
}

.hops-cmd-output {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
}

.hops-cmd-output pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.5;
}

.hops-cmd-input {
  display: flex;
  align-items: center;
  background-color: #111;
  padding: 10px;
  border-radius: 5px;
}

.hops-cmd-input span {
  margin-right: 10px;
  font-weight: bold;
}

.hops-cmd-input input {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: #0f0;
  font-family: 'Fira Code', monospace;
  font-size: 16px;
  outline: none;
}

.hops-cmd-input input::placeholder {
  color: #0f07;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.hops-cmd-input::after {
  content: '|';
  animation: blink 1s infinite;
  margin-left: 5px;
}