@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

.info-container {
  font-family: 'Share Tech Mono', monospace;
  background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
  color: #00ff00;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.info-content {
  flex-grow: 1;
  margin-bottom: 30px; /* Increased margin at the bottom of the content */
}

.info-headline {
  font-size: 28px; /* Increased font size */
  color: #00ff00;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.7);
}

.info-subheadline {
  font-size: 24px; /* Increased font size */
  color: #00ff00;
  margin-top: 30px;
  margin-bottom: 20px;
}

.info-step {
  font-size: 20px; /* Increased font size */
  color: #00ff00;
  margin-top: 25px;
  margin-bottom: 15px;
}

.info-text {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #00ff00;
}

.image-container {
  text-align: center;
  margin: 30px 0;
  position: relative;
  display: inline-block;
}

.info-image {
  max-width: 50%;
  height: auto;
  border: 2px solid #00ff00;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.image-container::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px solid #FFF;
  opacity: 0;
  transition: all 0.3s;
  pointer-events: none;
}

.image-container:hover::after {
  opacity: 1;
  animation: borderGlow 2s linear infinite;
}

@keyframes borderGlow {
  0%, 100% { clip-path: inset(0 0 98% 0); }
  25% { clip-path: inset(0 98% 0 0); }
  50% { clip-path: inset(98% 0 0 0); }
  75% { clip-path: inset(0 0 0 98%); }
}

.info-container a {
  color: #00ff00;
  text-decoration: none;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

.info-container a:hover {
  text-decoration: underline;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.7);
}

.link-emoji {
  margin-right: 5px;
  font-size: 18px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px; /* Added margin at the bottom */
}

.buy-button {
  background-color: #000;
  color: #00ff00;
  border: 2px solid #00ff00;
  padding: 15px 30px; /* Increased padding for larger button */
  text-decoration: none;
  font-size: 18px; /* Increased font size */
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block; /* Ensure the button takes full size */
}

.buy-button:hover {
  background-color: rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.info-container::-webkit-scrollbar {
  width: 8px;
}

.info-container::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.info-container::-webkit-scrollbar-thumb {
  background: #00ff00;
  border-radius: 4px;
}

.info-container::-webkit-scrollbar-thumb:hover {
  background: #00cc00;
}