@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.player-container {
  font-family: 'VT323', monospace;
  background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
  color: #00ff00;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.player-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.player-logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  animation: glow 1.5s ease-in-out infinite alternate;
}

.player-header h2 {
  font-size: 2.5em;
  margin: 0;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.7);
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    filter: drop-shadow(0 0 5px #00ff00);
  }
  to {
    filter: drop-shadow(0 0 10px #00ff00);
  }
}

.player-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.control-button {
  background: none;
  border: 2px solid #00ff00;
  color: #00ff00;
  font-size: 1.5em;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.control-button:hover {
  background-color: rgba(0, 255, 0, 0.2);
  transform: scale(1.1);
}

.control-button:active {
  transform: scale(0.95);
}

.volume-control {
  display: flex;
  align-items: center;
}

.volume-icon {
  font-size: 1.5em;
  margin-right: 10px;
}

.volume-slider {
  appearance: none;
  width: 100px;
  height: 5px;
  background: #00ff00;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.volume-slider:hover {
  opacity: 1;
}

.volume-slider::-webkit-slider-thumb {
  appearance: none;
  width: 15px;
  height: 15px;
  background: #00ff00;
  cursor: pointer;
  border-radius: 50%;
}

.volume-slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #00ff00;
  cursor: pointer;
  border-radius: 50%;
}

.song-list {
  margin-top: 20px;
}

.song-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(0, 255, 0, 0.3);
}

.song-item:hover {
  background-color: rgba(0, 255, 0, 0.1);
}

.song-item.active {
  background-color: rgba(0, 255, 0, 0.3);
  font-weight: bold;
}

@keyframes pulse {
  0% {
    background-color: rgba(0, 255, 0, 0.2);
  }
  50% {
    background-color: rgba(0, 255, 0, 0.3);
  }
  100% {
    background-color: rgba(0, 255, 0, 0.2);
  }
}

.song-number {
  font-size: 1.2em;
  margin-right: 15px;
  color: #00ff00;
}

.song-info {
  display: flex;
  flex-direction: column;
}

.song-title {
  font-size: 1.2em;
  font-weight: bold;
}

.song-artist {
  font-size: 0.9em;
  color: #00cc00;
}

.progress-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.progress-bar {
  appearance: none;
  width: 100%;
  height: 10px;
  background: #333;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  cursor: pointer;
}

.progress-bar:hover {
  opacity: 1;
}

.progress-bar::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background: #00ff00;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.8);
}

.progress-bar::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #00ff00;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.8);
}

.time-display {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 0.9em;
  color: #00cc00;
}