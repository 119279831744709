@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

.archives-container {
  font-family: 'Share Tech Mono', monospace;
  background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
  color: #00ff00;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.explorer-bar {
  font-family: Arial, sans-serif;
  background-color: #e0e0e0;
  color: #000;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #00ff00;
  margin-bottom: 15px;
  font-weight: normal;
}

.archives-intro {
  font-size: 16px;
  margin-bottom: 20px;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.7);
  line-height: 1.4;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.image-item {
  cursor: pointer;
  text-align: center;
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;
}

.image-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 3px solid #00ff00;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-item:hover::before {
  opacity: 1;
  animation: borderAnimation 2s linear infinite;
}

@keyframes borderAnimation {
  0% {
    clip-path: inset(0 100% 100% 0);
  }
  25% {
    clip-path: inset(0 0 100% 0);
  }
  50% {
    clip-path: inset(0 0 0 100%);
  }
  75% {
    clip-path: inset(100% 0 0 0);
  }
  100% {
    clip-path: inset(0 100% 100% 0);
  }
}

.image-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.image-item span {
  display: block;
  font-size: 14px;
  margin-top: 8px;
  color: #00ff00;
}

.image-viewer {
  text-align: center;
}

.image-viewer img {
  max-width: 100%;
  height: auto;
  border: 2px solid #00ff00;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.5);
}

.back-button, .download-link {
  background-color: #000;
  color: #00ff00;
  border: 2px solid #00ff00;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
  font-family: 'Share Tech Mono', monospace;
  transition: all 0.3s ease;
  display: inline-block;
  margin: 0 10px 20px;
  text-decoration: none;
}

.back-button:hover, .download-link:hover {
  background-color: rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.website-link, .archive-link {
  color: #00ff00;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
}

.website-link:hover, .archive-link:hover {
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.7);
  text-decoration: underline;
}

.link-emoji {
  margin-right: 5px;
  font-size: 18px;
}