@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: 0;
}

.loading-cmd {
  width: 80%;
  max-width: 800px;
  height: 80%;
  max-height: 600px;
  background-color: rgba(12, 12, 12, 0.9);
  border: 2px solid #00FF00;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.5);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.cmd-header {
  background-color: rgba(12, 12, 12, 0.9);
  color: #00FF00;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Fira Code', monospace;
  font-weight: bold;
  border-bottom: 1px solid #00FF00;
}

.cmd-controls {
  display: flex;
  gap: 5px;
}

.cmd-controls span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #00FF00;
}

.cmd-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  font-family: 'Fira Code', monospace;
  color: #00FF00;
  font-size: 14px;
}

.cmd-line {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.cmd-prompt {
  margin-right: 10px;
}

.cmd-text {
  animation: typing 1s steps(30, end);
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.cmd-progress {
  background-color: rgba(12, 12, 12, 0.9);
  color: #00FF00;
  padding: 10px;
  font-family: 'Fira Code', monospace;
  border-top: 1px solid #00FF00;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: rgba(26, 26, 26, 0.8);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 5px;
}

.progress-fill {
  height: 100%;
  background-color: #00FF00;
  transition: width 0.3s ease;
}

.desktop {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  user-select: none;
}

.desktop-icons {
  padding: 20px;
  height: calc(100% - 40px);
  position: relative;
}

.desktop-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  height: 90px;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  transition: all 0.3s ease;
}

.desktop-icon.selected {
  background-color: rgba(76, 175, 80, 0.3);
  border-radius: 5px;
}

.desktop-icon img {
  width: 48px;
  height: 48px;
}

.desktop-icon span {
  margin-top: 5px;
  font-size: 12px;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  text-align: center;
  word-wrap: break-word;
}

.window {
  background-color: white;
  border: 1px solid #4CAF50;
  border-radius: 3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.window.maximized {
  width: 100% !important;
  height: calc(100vh - 40px) !important;
  top: 0 !important;
  left: 0 !important;
}

.window-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
}

.window-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.window-title {
  font-size: 14px;
  font-weight: 500;
  flex-grow: 1;
}

.window-controls {
  display: flex;
  align-items: center;
}

.window-control-button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 5px;
  border-radius: 3px;
}

.window-control-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

.window-control-button:active {
  transform: scale(0.95);
}

.window-control-button.minimize:hover {
  background-color: #ffc107;
  color: #333;
}

.window-control-button.maximize:hover {
  background-color: #2196F3;
}

.window-control-button.close:hover {
  background-color: #f44336;
}

.window-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.taskbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background-color: rgba(74, 175, 80, 0.8);
  display: flex;
  align-items: center;
  padding: 0 10px;
  z-index: 1000;
  backdrop-filter: blur(10px);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
}

.start-button {
  background-color: #45a049;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  border-radius: 5px;
  margin-right: 5px;
}

.start-button:hover {
  background-color: #3d8b3d;
}

.start-menu {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 400px;
  background-color: #f0f0f0;
  border: 1px solid #4CAF50;
  border-top: none;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  z-index: 1001;
}

.start-menu-column {
  flex: 1;
  padding: 10px;
}

.start-menu h3 {
  margin-bottom: 10px;
  color: #4CAF50;
}

.start-menu ul {
  list-style-type: none;
}

.start-menu li {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.start-menu li:hover {
  background-color: #e0e0e0;
}

.start-menu img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.start-menu a, .start-menu span {
  text-decoration: none;
  color: #333;
}

.taskbar-items {
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow-x: auto;
  margin: 0 10px;
}

.taskbar-item {
  background-color: #45a049;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-right: 2px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  display: flex;
  align-items: center;
}

.taskbar-item:hover {
  background-color: #3d8b3d;
}

.taskbar-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.status-bar {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
}

.status-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.status-label {
  font-weight: bold;
  margin-right: 5px;
}

.status-value {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2px 8px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.status-value::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3));
  z-index: -1;
  transform: skew(-20deg);
}

.status-value.online::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #4CAF50;
  border-radius: 50%;
  margin-left: 5px;
  box-shadow: 0 0 10px #4CAF50;
}

.status-value.offline::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #f44336;
  border-radius: 50%;
  margin-left: 5px;
  box-shadow: 0 0 10px #f44336;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}

.status-value.online::after {
  animation: pulse 2s infinite;
}

@media (max-width: 768px) {
  .desktop-icon {
    width: 60px;
    height: 70px;
  }

  .desktop-icon img {
    width: 36px;
    height: 36px;
  }

  .desktop-icon span {
    font-size: 10px;
  }

  .window {
    width: 100% !important;
    height: calc(100vh - 40px) !important;
    top: 0 !important;
    left: 0 !important;
    border-radius: 0;
  }

  .window-header {
    padding: 8px;
  }

  .window-control-button {
    width: 30px;
    height: 25px;
    font-size: 14px;
  }

  .start-menu {
    width: 100%;
    flex-direction: column;
  }

  .status-bar {
    display: none;
  }

  .taskbar-items {
    overflow-x: auto;
  }

  .taskbar-item {
    max-width: 100px;
  }

  .start-button {
    font-size: 14px;
    padding: 3px 8px;
  }

  .cmd-content {
    font-size: 12px;
  }

  .cmd-header {
    padding: 5px;
  }

  .cmd-controls span {
    width: 10px;
    height: 10px;
  }

  .progress-bar {
    height: 15px;
  }
}

@media (hover: none) and (pointer: coarse) {
  .window-control-button,
  .taskbar-item,
  .start-button,
  .desktop-icon {
    min-height: 44px;
    min-width: 44px;
  }

  .window-control-button {
    margin-left: 10px;
  }

  .taskbar {
    height: 50px;
  }

  .window.maximized {
    height: calc(100vh - 50px) !important;
  }

  .start-menu li {
    padding: 10px 5px;
  }

  .start-menu img {
    width: 32px;
    height: 32px;
  }
}

.desktop-icon.selected {
  background-color: rgba(76, 175, 80, 0.3);
  border-radius: 5px;
}