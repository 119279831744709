.explorer {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #000;
    color: #0f0;
    font-family: 'Fira Code', monospace;
  }
  
  .explorer-toolbar {
    flex-shrink: 0;
    padding: 10px;
    background-color: #111;
    border-bottom: 2px solid #0f0;
  }
  
  .static-url {
    font-size: 14px;
    padding: 8px 12px;
    background-color: #222;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .explorer-content {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 255, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #0f0;
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .explorer-iframe {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    border: none;
    background-color: #ffffff;
  }